/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
     
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'  style={{ marginTop: "20px" }}>
        <div className='menu-content pt-8 pb-2' >
          <span className='menu-section text-muted text-uppercase fs-8 ls-1' >Growbiz</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='School'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/crafted/school/students' title='Students' hasBullet={true} />
        <AsideMenuItem to='/crafted/school/gateways' title='Gateways' hasBullet={true} />
        <AsideMenuItem to='/crafted/school/pings' title='Pings' hasBullet={true} />

      </AsideMenuItemWithSub>
      

      
     
    </>
  )
}
