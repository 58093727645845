/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { Button } from 'react-bootstrap-v5'

type Props = {
  dnix: string
}
const DetailStudent: FC<Props> = ({dnix}) => {
  
  const [dataStudentPing, setDataStudentPing] = useState<any[]>([]);
  const [unixTimeInicio, setUnixTimeInicio] = useState(Math.floor(new Date(new Date().getTime() - 5 * 60 * 1000).getTime() / 1000));
  const [unixTimeFin, setUnixTimeFin] = useState(Math.floor(Date.now() / 1000));

  useEffect(() => {
    console.log(dnix);
    //setDataStudentPing([]);
    fetchDataPing();
  }, [dnix]);
  

  const fetchDataPing = async () => {
    setDataStudentPing([]);
    console.log("unixTimeInicio", unixTimeInicio);
    console.log("unixTimeFin", unixTimeFin);

    var params = {
      "table": "ping",
      "filter": {
          "timeIni": unixTimeInicio.toString(),
          "timeFin": unixTimeFin.toString(),
          "dni": dnix
      }
    }
    fetch("https://mmec90bpnc.execute-api.us-east-1.amazonaws.com/dev/scan", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params)
    })
    .then((response) => response.json())
    .then((data) => {
      console.log("Mensaje enviado:", data);
      setDataStudentPing(data);
    })
    .catch((error) => {
      console.error("Error al enviar el mensaje:", error);
    });
  }
  

  return (
    <div className='modal fade' id='kt_modal_details_students' aria-hidden='true'>
      <div className='modal-dialog mw-400px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            
          <div className='card-toolbar'>
              <button  
                className='btn btn-sm btn-light-primary'
                onClick={fetchDataPing}
                >
                <KTSVG path='/media/icons/duotune/files/fil011.svg' className='svg-icon-3' />
                Refresh
              </button>
            </div>
            <br/>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Student Details</h1>
              <h2 className='mb-3'>{dnix}</h2>
            </div>
            <div className='card-body pt-5'>
              {/* begin::Timeline */}
              <div className='timeline-label'>
                {
                  dataStudentPing.length > 0 ?
                    dataStudentPing.map((det, indice) =>{
                      return (
                        <div className='timeline-item'>
                          <div className='timeline-label fw-bolder text-gray-800 fs-8'>{det.ultima_lectura_date.slice(-8)}</div>
                          <div className='timeline-badge'>
                            <i className='fa fa-genderless text-success fs-1'></i>
                          </div>
                          <div className='timeline-content d-flex'>
                            <span className='fw-bolder text-gray-800 ps-3'>{det.ubicacion}</span>
                          </div>
                        </div>
                      )
                    })
                    : 
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                }
              </div>
              {/* end::Timeline */}
            </div>           
          </div>
        </div>
      </div>
    </div>
  )
}

export {DetailStudent}
