/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { Button } from 'react-bootstrap-v5'

type Props = {
  dnix: string
}
const UpdateStudent: FC<Props> = ({dnix}) => {

  return (
    <div className='modal fade' id='kt_modal_update_students' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Update Student</h1>
            </div>

            <input type='text' className='form-control form-control-solid mb-8' placeholder='Type or paste ID' disabled>
            </input>

            <input type='text' className='form-control form-control-solid mb-8' placeholder='Type or paste Name'>
            </input>

            <input type='text' className='form-control form-control-solid mb-8' placeholder='Type or paste Lastname'>
            </input>

            <input type='text' className='form-control form-control-solid mb-8' placeholder='Type or paste MAC Beacon'>
            </input>

            <input type='file' className='form-control form-control-solid mb-8' placeholder='Type or paste MAC Beacon'>
            </input>

            <div className='text-center mb-13'>
              <button className='btn btn-primary align-self-center'>
                Save
              </button>
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  )
}

export {UpdateStudent}
