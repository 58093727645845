/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, FC} from 'react'
import {useState} from 'react';
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { Button } from 'react-bootstrap-v5'

const CreateStudent: FC = () => {
  
  const [dni, setDNI] = useState("");
  const [name, setName] = useState("");
  const [company_id, setCompany] = useState("");
  const [mac, setMAC] = useState("");
  const [url, setURL] = useState("");

  const dniHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDNI(e.target.value);
  };
  const nameHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const companyHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
  };
  const macHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMAC(e.target.value);
  };
  const urlHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setURL(e.target.value);
  };

  function saveStudent(){
    var params = {
      "table": "usuario",
      "dni": dni,
      "companyid": company_id,
      "dmac": mac,
      "imageurl": url,
      "nombre": name,
    }
    fetch("https://mmec90bpnc.execute-api.us-east-1.amazonaws.com/dev/putitem", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params)
    })
    .then((response) => response.json())
    .then((data) => {
      alert("Registro grabado correctamente")
    })
    .catch((error) => {
      console.error("Error al enviar el mensaje:", error);
    });
  }


  return (
    <div className='modal fade' id='kt_modal_create_students' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Create Student</h1>
            </div>

            <input type='text' id="dni" className='form-control form-control-solid mb-8' onChange={dniHandleChange} placeholder='Type or paste ID'>
            </input>

            <input type='text' id="name" className='form-control form-control-solid mb-8' onChange={nameHandleChange} placeholder='Type or paste Name'>
            </input>

            <input type='text' id="company_id" className='form-control form-control-solid mb-8' onChange={companyHandleChange} placeholder='Type or paste Company ID'>
            </input>

            <input type='text' id="mac" className='form-control form-control-solid mb-8' onChange={macHandleChange} placeholder='Type or paste MAC Beacon'>
            </input>

            <input type='text' id="url" className='form-control form-control-solid mb-8' onChange={urlHandleChange} placeholder='Type or paste Image Url'>
            </input>

            <div className='text-center mb-13'>
              <button className='btn btn-primary align-self-center' onClick={saveStudent}>
                Save
              </button>
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  )
}

export {CreateStudent}
